const state = {
  articleTypes: []
}
const mutations = {
  setArticleTypes(state, payload) {
    state.articleTypes = payload
  }
}

const actions = {

}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}