import axios from 'axios'
import store from "@/store";
import {Notify} from 'vant'
import {Toast} from 'vant'
import {REQUEST_BASE_URL} from "@/config/config";

const service = axios.create({
  baseURL: REQUEST_BASE_URL, // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 60000 // request timeout
})

// request interceptor
service.interceptors.request.use(
  config => {
    // do something before request is sent
    config.headers['Authorization'] = 'Basic YmJzOmJic19jbGllbnQ='
    config.headers['Tenant-Id'] = '000000'
    let user = store.getters.user;
    if (user.access_token) {
      config.headers['GeekMore-Auth'] = user.access_token;
    }
    return config
  },
  error => {
    // do something with request error
    console.log(error) // for debug
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  /**
   * If you want to get http information such as headers or status
   * Please return  response => response
   */

  /**
   * Determine the request status by custom code
   * Here is just an example
   * You can also judge the status by HTTP Status Code
   */
  response => {
    const res = response.data

    // if the custom code is not 20000, it is judged as an error.
    if (res.code !== 200) {
      /* Message({
           message: res.message || 'Error',
           type: 'error',
           duration: 5 * 1000
       })*/

      // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
      if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
        // to re-login

      }
      if (res.code === 450) return Promise.reject({code: res.code, msg: res.msg})

      const {msg} = res
      Notify({type: 'danger', message: msg});
      return Promise.reject(new Error(msg || 'Error'))
    } else {
      return res.data
      // const list = [
      //   '/api/zeekr-article-new/article/listUser',
      // ]
      // const url = response.config.url
      // if(list.indexOf(url) !== -1){
      //   const {data} = res || {}
      //   let {records} = data || []
      //   if(records.length > 0){
      //     console.log(records)
      //     records = records.map(item=>{
      //       return {
      //         ...item,
      //         ...item.userBasicVO
      //       }
      //     })
      //     data.records = records
      //     return data
      //   }else {
      //     return data
      //   }
      // }else {
      //   return res.data
      // }
    }
  },
  error => {
    const {response} = error || {}
    const {data} = response || {}
    const {code} = data
    if (code === 400) {
      Toast.fail(data.msg)
    }
    return Promise.reject(error)
  }
)

export default service
