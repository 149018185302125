<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  created() {
    window.onload = function () {
      // 阻止双击放大
      let lastTouchEnd = 0
      document.addEventListener('touchstart', function (event) {
        if (event.touches.length > 1) {
          event.preventDefault()
        }
      })
      document.addEventListener('touchend', function (event) {
        let now = (new Date()).getTime()
        if (now - lastTouchEnd <= 300) {
          event.preventDefault()
        }
        lastTouchEnd = now
      }, false)
      // 阻止双指放大
      document.addEventListener('gesturestart', function (event) {
        event.preventDefault();
      })
    }
  }
}
</script>
<style lang="scss">
#app {
  font-family: PingFang SC, PingFang SC-Medium Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // /*// 兼容 iOS < 11.2*/
  // padding-bottom: constant(safe-area-inset-bottom);
  // /*// 兼容 iOS >= 11.2*/
  // padding-bottom: env(safe-area-inset-bottom);
  padding-bottom: 10px;
}

::-webkit-scrollbar {
  width: 0;
}
</style>
