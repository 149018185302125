import CryptoJS from 'crypto-js'
import {getCircleDetail} from "@/api/circle";
import {BR, DIV_END, DIV_START} from "@/config/config";

export function GetQueryString (name) {
  let reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
  let r = window.location.search.substr(1).match(reg)
  if (r != null) return unescape(r[2])
  return null
}


export function isURL (url) {
  var urlRegExp = /^(https|http)?:\/\//;
  return urlRegExp.test(url);
}

//判断是否是视频
export function isVideo (src) {
  const videoTypes = "avi,wmv,mpg,mpeg,mov,rm,ram,swf,flv,mp4,mp3,wma,avi,rm,rmvb,flv,mpg,mkv"
  const index = src.lastIndexOf('.')
  let type = src.substring(index + 1)
  type = type.toLowerCase()
  return videoTypes.indexOf(type) !== -1
}


/**
 * 判断是否是闪布APP打开
 * @returns {boolean}
 */
export function isThunbuApp () {
  return window.navigator.userAgent.toLocaleLowerCase().indexOf('thunbu') > -1
}


//加密
export function Encrypt (word) {
  const key = CryptoJS.enc.Utf8.parse('o7H8uIM2O5qv65l2');//Latin1 w8m31+Yy/Nw6thPsMpO5fg==
  const srcs = CryptoJS.enc.Utf8.parse(word);
  const encrypted = CryptoJS.AES.encrypt(srcs, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7});
  return encrypted.toString();
}

export function getTimestamp (str) {
  if (str && str.indexOf("-")) {
    str = str.replaceAll("-", "/")
    return new Date(str).getTime();
  } else {
    return str;
  }
}


/**
 * 是否是企业微信浏览器
 * @returns {boolean}
 */
export function isWechatEnterpriseBrowser () {
  let ua = navigator.userAgent.toLowerCase();
  let regWechat = /MicroMessenger/i
  let isWechat = regWechat.test(ua);
  if (!isWechat) {
    return false;
  } else {
    let regEnterpriseWechat = /WxWork/i
    return regEnterpriseWechat.test(ua);
  }
}

/**
 * 是否是企业微信手机浏览器
 * @returns {boolean}
 */
export function isWechatEnterpriseMobile () {
  let ua = navigator.userAgent.toLowerCase();
  let regEnterpriseWechatMobile = /mobile/i
  return isWechatEnterpriseBrowser() && regEnterpriseWechatMobile.test(ua);
}

/**
 * 处理高亮
 * @returns {boolean}
 */
export function handleHeightLight (str, keyword) {
  if (str.indexOf(keyword) >= 0) {
    let reg = new RegExp(keyword, "g")
    return str.replace(reg, `<span style="color: #FE5800">${keyword}</span>`)
  }
  return str
}

export function handleContentHeightLight (str, keyword) {

  let text = handleContent(str)
  let el = document.createElement('div')
  el.innerHTML = text

  let font = el.querySelectorAll('font')

  let reg = new RegExp(keyword, "g")
  if (font.length) {
    for (let i = 0; i < font.length; i++) {

      let newTetx = font[i].innerText.replace(reg, `<span style="color: #FE5800">${keyword}</span>`)
      let newfont = document.createElement('font')
      newfont.innerHTML = newTetx

      const parent = font[i].parentNode
      parent.replaceChild(newfont, font[i])
    }
  }
  return el.innerHTML
}

/**
 * 将文本中的 圈子信息提取出来
 * @param text
 * @returns {*}
 */
export function handleTextContent (text) {
  // 将文本中的圈子信息替换出来
  let circleInfo = /{"content":"[\s\S]*?","id":"?[0-9]*"?,"type":1}/g
  let circleResult = text.match(circleInfo)
  window.abc = (id) => {
    getCircleDetail(id).then(() => {
      window.open(`/app/circle/circleXq?id=${id}`, '_self')
    }).catch(err => {
      console.log(err.request.responseText)
      let msg = JSON.parse(err.request.responseText).msg
      console.log(msg)
      // this.$notify({ type: 'warning', message: msg })
    })
  }
  circleResult.forEach(item => {
    let obj = {}
    try {
      obj = JSON.parse(item)
      let circleHtml
      circleHtml = `<span onclick="window.abc('${obj.id}')" class="circleText" id="${obj.id}" type="${obj.type}">${obj.content}</span>`
      text = text.replace(item, circleHtml)
    } catch {
      text = text.replace(item, '')
      console.info('存在特殊字符等，解析失败！')
    }
  })
  let circleHtmlProvisions = `<a class="provisionsText">《不氪套社区发言公约》</a>`
  text = text.replace("${document.bbs-speech-rule}", circleHtmlProvisions)
  // 将圈子中的@替换出来
  return text
}

// 帖子内容解析出@的人，换行替换成<br />
export function handleContent (text) {
  // 创建匹配@的人和标签正则
  let at = /{"type":1,"identity":"article","id":"[0-9]*","content":"[\s\S]*?"}/g
  const atResult = text.match(at)
  // 替换第一行
  let startIndex = text.indexOf(DIV_START);
  if (startIndex !== -1) {
    let startStr = text.substring(0, startIndex);
    text = text.replace(startStr, DIV_START + startStr + DIV_END)
  }
  text = replaceStringALl(text, DIV_START + BR + DIV_END, "\n")
  text = replaceStringALl(text, DIV_START, "")
  text = replaceStringALl(text, DIV_END, "\n")
  if (atResult) {
    let oldText = text
    let TextArr = []
    let mergeArr = []
    let itemArys = [];
    let minLength = null
    TextArr = oldText.split(at)
    let atIndex = text.indexOf(atResult[0])
    let textIndex = text.indexOf(TextArr[0].replace(/\n/g, '').trim())

    //将两个数组中较长的数组记录下来
    let longAry = atResult.length > TextArr.length ? atResult : TextArr;
    //先拿到两个数组中长度较短的那个数组的长度
    if (atResult.length > TextArr.length) {
      minLength = TextArr.length;
    } else {
      minLength = atResult.length;
    }
    //循环范围为较短的那个数组的长度
    for (var i = 0; i < minLength; i++) {
      //将数组放入临时数组中
      if (atIndex < textIndex) {
        mergeArr.push(atResult[i]);
        mergeArr.push(TextArr[i])
      } else {
        mergeArr.push(TextArr[i]);
        mergeArr.push(atResult[i])
      }

    }

    //和多余的新数组拼接起来并返回。
    itemArys = mergeArr.concat(longAry.slice(minLength))
    let newArr = []

    itemArys.forEach((item, index) => {
      if (item.trim()) {
        try {
          const obj = JSON.parse(item)
          if (typeof obj == 'object') {
            let atLink = `<a type="1" id="${obj.id}" class="label" name="${obj.content}" href="/app/home/userInfoDetail/${obj.id}">@${obj.content}</a>`
            newArr[index] = atLink
          } else {
            newArr[index] = `<font>${item}</font>`
          }
        } catch (error) {
          newArr[index] = `<font>${item}</font>`
        }
      }
    })
    text = newArr.join('')
  }

  return text
}

function replaceStringALl (str, replace, des) {
  let replaceIndex = str.indexOf(replace);
  if (replaceIndex !== -1) {
    str = str.replace(replace, des);
    return replaceStringALl(str, replace, des)
  }
  return str;
}

export function initViewport (height) {
  var metaEl = document.querySelector("#viewportMeta");
  var content = "height=" + height + ",width=device-width,initial-scale=1.0,user-scalable=no";
  metaEl.setAttribute('name', 'viewport');
  metaEl.setAttribute('content', content);
}

// 获取图片真实宽高的缩略图
export function getIgnoreImagesWidthAndHeight (imageUrl) {
  return imageUrl + "?x-oss-process=image/resize,l_400";
}

// 获取图片等比缩放十分之一
export function getOssImgUrlOneTenth (imageUrl) {
  return imageUrl + "?x-oss-process=image/resize,p_10";
}

// van-field输入框过滤
export function vanFieldFormatter (value) {
  let newValue = value.replace(/&/g, '')
  newValue = newValue.replace(/>/g, '')
  newValue = newValue.replace(/</g, '')
  // newValue = newValue.replace(/"/g, '')
  // newValue = newValue.replace(/'/g, '')
  return newValue
}
