import Vue from 'vue'
import VueRouter from 'vue-router'
import {routerOptions as routes} from './routerOption'
import store from "../store";
import {isWechatEnterpriseBrowser} from "@/utils/utils";

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes
})

let whiteList = ["/wxchatcode", "/login", "/secret" ,"/wechat_login"]
router.beforeEach((to, from, next) => {
    // 判断用户是否已经登录了
    if (isWechatEnterpriseBrowser()) {
        let isSueccessLogin = store.getters.user  && store.getters.user.access_token
        let inWhiteList = whiteList.indexOf(to.path) >= 0;
        if (!isSueccessLogin && !inWhiteList) {
            // alert("拦截到企业微信登录！"+ to.path)
            // 企业微信，拦截到企业微信页面
            window.localStorage.setItem("wechatSharePage", window.location.href)
            window.location.replace(`${window.location.protocol}//${window.location.host}/wechat_login.html`)
        } else {
            next()
        }
    }else{
        next()
    }
})

export default router
