import routerView from "@/components/routerView";

export default {
    name: 'home',
    path: 'home',
    component: routerView,
    meta: {title: '首页', activeIcon: 'homeActive.png', normalIcon: 'homeNormal.png', moduleName: 'home'},
    redirect: {name: 'main'},
    children: [
        {
            // 主页
            path: 'main',
            name: 'main',
            meta: {moduleName: 'home', keepAlive: true},
            component: () => import('@/views/app/home/main')
        },
        {
            // 热门
            path: 'popular',
            name: 'popular',
            meta: {moduleName: 'home', hideTabBar: true},
            component: () => import('@/views/app/home/popular')
        },
        {
            // 活动
            path: 'activity',
            name: 'activity',
            meta: {moduleName: 'home', hideTabBar: true},
            component: () => import('@/views/app/home/activity')
        },
        {
            // 排名
            path: 'rank',
            name: 'rank',
            meta: {moduleName: 'home', hideTabBar: true},
            component: () => import('@/views/app/home/rank')
        },
        {
            // 编辑话题
            path: 'createTopic',
            name: 'createTopic',
            meta: {moduleName: 'home', hideTabBar: true},
            props: route => route.query,
            component: () => import('@/views/app/home/createTopic')
        },
        {
            // 添加投票
            path: 'vote',
            name: 'vote',
            meta: {moduleName: 'home', hideTabBar: true},
            props: route => route.query,
            component: () => import('@/views/app/home/createTopic/vote')
        },
        {
            // 编辑话题
            path: 'topicCategory',
            name: 'topicCategory',
            meta: {moduleName: 'home', hideTabBar: true},
            props: route => route.query,
            component: () => import('@/views/app/home/topicCategory')
        },
        {
            // 话题详情
            path: 'topicDetail',
            name: 'topicDetail',
            meta: {moduleName: 'home', hideTabBar: true},
            props: route => route.query,
            component: () => import('@/views/app/home/topicDetail')
        },
        {
            // 用户主页
            path: 'userInfoDetail/:userId',
            name: 'userInfoDetail',
            meta: {moduleName: 'home', hideTabBar: true},
            props: route => route.params,
            component: () => import('@/views/app/home/userInfoDetail')
        },
        {
            // 标签搜索
            path: 'searchTag',
            name: 'searchTag',
            meta: {moduleName: 'home', hideTabBar: true},
            props: route => route.query,
            component: () => import('@/views/app/home/searchTag')
        }
    ]
}
