
// 用户默认头像
export const USER_IMAGE = "https://community-echo.oss-cn-hangzhou.aliyuncs.com/upload/20210511/451c803226ee86ea86d68b6773d539a1.png";
export const ADMIN_IMAGE = "https://hx1.xuanyu.com.cn/img/hrfeE8dnBQ.png";

export const REQUEST_BASE_URL = process.env.NODE_ENV === 'production'? "":''

// 刷新消息时间 默认5秒
export const FLUSH_TIME_SECONDS = 1;

// 超级管理员ID
export const SUPER_ADMIN_ID = 1000000001;

export const ALI_OSS_TOKEN_URL = '/api/zeekr-resource/aliyun/sts/token'

export const ADMIN_NAME = '官方助手'

// 群聊身份配置
export const CRICLE_IDENTITY = {
    "1": '圈主',
    "2": '管理员',
    "3": ''
}

export const BR= "==**=="
export const DIV_START = "===]";
export const DIV_END = "[==="
