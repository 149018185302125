import request from "@/utils/request";

export function getOSSSignature() {
    return request({
        url: '/api/zeekr-resource/aliyun/sts/token',
        method: 'get'
    })
}

/**
 * 获取图片信息
 */
export function getOSSPictureInfo(url) {
    if(!url){
        return
    }
    return request({
        url: `${url}?x-oss-process=image/info`,
        method: 'get'
    })
}
