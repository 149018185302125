import request from "@/utils/request";
import store from "@/store";

/**
 * 根据用户 id 获取用户信息
 * @param id
 * @returns {AxiosPromise}
 */
export function getUserById(id = '') {
  if (store.getters.user.user_id !== id) {
    return getUser(id)
  }
  return new Promise((resolve) => {
    let detail = store.getters.userDetail
    if (Object.keys(detail).length > 0) {
      resolve(store.getters.userDetail)
    } else {
      let requestObj = {
        url: '/api/zeekr-article-new/zeekrUser/getUser',
        method: 'get',
      }
      if (id) {
        requestObj.params = {
          userId: id
        }
      }
      request(requestObj).then(res => {
        store.commit('user/SET_USER_DETAIL', res);
        resolve(res)
      })
    }
  })
}

/**
 * 获取用户信息
 */
export function getUser(id) {
  return request({
    url: '/api/zeekr-article-new/zeekrUser/getUser',
    method: 'get',
    params: {
      userId: id
    }
  })
}

/**
 * 查询极客用户
 */

export function getZeekrUser(data) {
  return request({
    url: '/api/zeekr-article-new/zeekrUser/list',
    method: 'post',
    data
  })
}

/**
 * 修改头像
 * @param avatar
 * @param id
 * @returns {AxiosPromise}
 */
export function updateAvatar(avatar, id) {
  return request({
    // url: '/api/zeekr-article/bbs/suser/update_avatar',
    url: '/api/zeekr-article-new/zeekrUser/updateAvatar',
    method: 'get',
    params: {
      avatar,
      id
    }
  })
}

/**
 * 获取登录次数
 * @returns {AxiosPromise}
 */
export function loginCount() {
  return request({
    url: '/api/zeekr-article-new/zeekrUser/getLoginCount',
    method: 'get'
  })
}

/**
 * 登录次数+1
 * @returns {AxiosPromise}
 */
export function addLoginCount() {
  return request({
    url: '/api/zeekr-article-new/zeekrUser/addLoginCount',
    method: 'get'
  })
}

/**
 * 同意須知
 * @returns {AxiosPromise}
 */
export function agreeUserNotice() {
  return request({
    url: '/api/zeekr-article-new/zeekrUser/agreeUserNotice',
    method: 'get'
  })
}

/**
 * 获取超级管理员
 * @returns {AxiosPromise}
 */
export function superAdmin() {
  return request({
    url: '/api/zeekr-article/bbs/suser/get-super-admin',
    method: 'get'
  })
}

/**
 * 根据用户名查询人员
 * @param name
 * @param pageNum
 * @param pageSize
 * @returns {AxiosPromise}
 */
export function getUserByName(params) {
  return request({
    url: '/api/zeekr-article/bbs/suser/get-by-name',
    method: 'get',
    params
  })
}

export function getUserByNameNew(data) {
  return request({
    url: '/api/zeekr-article-new/zeekrUser/list',
    method: 'post',
    data
  })
}

