import home from './modules/home'
import mine from './modules/mine'
import message from './modules/message'
import common from './modules/common'
import circle from './modules/circle'

export const routerOptions = [
    {
        path: '/',
        redirect: {name: 'app'}
    },

    {
        path: '/login',
        name: 'login',
        component: () => import('@/views/login')
    },
    {
        path: '/wxchatcode',
        name: 'wxchatcode',
        component: () => import('@/views/login/wxchatcode')
    },
    {
        path: '/app',
        name: 'app',
        redirect: {name: 'home'},
        component: () => import('@/views/app/layout'),
        children: [
            home,
            circle,
            message,
            mine,
            common,
        ]
    },
    {
        path: '/secret',
        name: 'secret',
        component: () => import('@/views/app/common/secretLogin')
    }
]
