import routerView from "@/components/routerView";

export default {
  name: 'mine',
  path: 'mine',
  meta: {title: '我的', activeIcon: 'mineActive.png', normalIcon: 'mineNormal.png', moduleName: 'mine'},
  component: routerView,
  redirect: {name: 'mineMain'},
  children: [
    {
      // 主页
      path: 'mineMain',
      name: 'mineMain',
      meta: {moduleName: 'mine'},
      component: () => import('@/views/app/mine/main')
    },
    {
      // 我的帖子
      path: 'post',
      name: 'minePost',
      meta: {moduleName: 'mine', hideTabBar: true},
      component: () => import('@/views/app/mine/post')
    },
    {
      // 设置头像框
      path: 'avatar/frame',
      name: 'mineAvatarFrame',
      meta: {moduleName: 'mine', hideTabBar: true},
      component: () => import('@/views/app/mine/avatarFrame')
    },
    {
      // 浏览记录
      path: 'browse',
      name: 'mineBrowse',
      meta: {moduleName: 'mine', hideTabBar: true},
      component: () => import('@/views/app/mine/browse')
    },
    {
      // 我管理的圈子
      path: 'circle',
      name: 'mineCircle',
      meta: {moduleName: 'mine', hideTabBar: true},
      component: () => import('@/views/app/mine/circle')
    },
    {
      // 我的点赞
      path: 'thumb',
      name: 'mineThumb',
      meta: {moduleName: 'mine', hideTabBar: true},
      component: () => import('@/views/app/mine/thumb')
    },
    {
      // 我的关注
      path: 'follow',
      name: 'mineFollow',
      meta: {moduleName: 'mine', hideTabBar: true},
      component: () => import('@/views/app/mine/follow')
    },
    {
      // 我的收藏
      path: 'collect',
      name: 'mineCollect',
      meta: {moduleName: 'mine', hideTabBar: true},
      component: () => import('@/views/app/mine/collect')
    }
  ]
}
