let userInfoStr = sessionStorage.getItem("userInfo");
let userInfo = {}
if(userInfoStr){
    userInfo = JSON.parse(userInfoStr)
}

let userDetailStr = sessionStorage.getItem("userDetail");
let userDetail = {}
if(userDetailStr){
    userDetail = JSON.parse(userDetail)
}

let localAndroid = localStorage.getItem("isAndroid");
let isAndroid = true
if (localAndroid) {
    isAndroid = localAndroid === 'true';
}

let originState =  {
    userInfo,
    isAndroid,
    userDetail,
    userDetailId: '',
}
const state = JSON.parse(JSON.stringify(originState))
const mutations = {
    SET_USERINFO: (state, userInfo) => {
        sessionStorage.setItem("userInfo", JSON.stringify(userInfo))
        state.userInfo = userInfo
    },
    SET_USERDETAILID: (state,userDetailId) =>{
        state.userDetailId = userDetailId
     },

    SET_DEVICE: (state, isAndroid) => {
        localStorage.setItem("isAndroid", isAndroid)
        state.isAndroid = isAndroid
    },

    // 存储用户详细信息
    SET_USER_DETAIL: (state, userDetail) => {
        localStorage.setItem("userDetail", userDetail)
        state.userDetail = userDetail
    },

    // 退出登录
    LOG_OUT:(state)=>{
        state.userDetail = {}
        state.userInfo = {}
        window.sessionStorage.clear()
    }
}

const actions = {

}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
