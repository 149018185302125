import routerView from "@/components/routerView";

export default {
  name: 'circle',
  path: 'circle',
  meta: {title: '圈子', activeIcon: 'circleActive.png', normalIcon: 'circleNormal.png', moduleName: 'circle'},
  component: routerView,
  redirect: {name: 'circleMain'},
  children: [
    {
      // 主页
      path: 'circleMain',
      name: 'circleMain',
      meta: {moduleName: 'circle'},
      component: () => import('@/views/app/circle/main')
    },
    {
      // 我的圈子,全部圈子
      path: 'circleMy',
      name: 'circleMy',
      meta: {moduleName: 'circle'},
      component: () => import('@/views/app/circle/circleMy')
    },
    {
      // 创建圈子
      path: 'myCreateCircle',
      name: 'myCreateCircle',
      meta: {moduleName: 'circle', hideTabBar: true},
      component: () => import('@/views/app/circle/myCreateCircle')
    },
    {
      // 全部成员
      path: 'myCreateCircleAllPe',
      name: 'myCreateCircleAllPe',
      meta: {moduleName: 'circle'},
      component: () => import('@/views/app/circle/circleMy/myCircle/myCreateCircle/myCreateCircleAllPe')
    },
    {
      // 圈子搜索
      path: 'circleSearch',
      name: 'circleSearch',
      meta: {moduleName: 'circle', hideTabBar: true},
      component: () => import('@/views/app/circle/circleSearch')
    },
    {
      // 圈子成员添加
      path: 'batchAdd',
      name: 'batchAdd',
      meta: {moduleName: 'circle', hideTabBar: true},
      component: () => import('@/views/app/circle/circleMy/myCircle/myCreateCircle/batch/batchAdd')
    },
    {
      // 圈子成员删除
      path: 'batchDel',
      name: 'batchDel',
      meta: {moduleName: 'circle', hideTabBar: true},
      component: () => import('@/views/app/circle/circleMy/myCircle/myCreateCircle/batch/batchDel')
    },
    {
      // 批量添加圈子成员
      path: 'myCreateCircleSearch',
      name: 'myCreateCircleSearch',
      meta: {moduleName: 'circle', hideTabBar: true},
      component: () => import('@/views/app/circle/circleMy/myCircle/myCreateCircle/myCreateCircleSearch')
    },
    {
      // 圈子详情
      path: 'circleXq',
      name: 'circleXq',
      meta: {moduleName: 'circle', hideTabBar: true, keepAlive: true},
      component: () => import('@/views/app/circle/circleXq')
    },
    {
      // 帖子详情
      path: 'circleTz',
      name: 'circleTz',
      meta: {moduleName: 'circle', hideTabBar: true},
      component: () => import('@/views/app/circle/components/circleXq/circleTz')
    },
    {
      // 圈子管理
      path: 'circleJbglsz',
      name: 'circleJbglsz',
      meta: {moduleName: 'circle', hideTabBar: true},
      component: () => import('@/views/app/circle/components/circleQgl/circleJbglsz')
    },
    {
      // 圈子管理员设置
      path: 'circleQglsz',
      name: 'circleQglsz',
      meta: {moduleName: 'circle', hideTabBar: true},
      component: () => import('@/views/app/circle/components/circleQgl/circleQglsz')
    },
    {
      // 圈子基本信息
      path: 'circleQgl',
      name: 'circleQgl',
      meta: {moduleName: 'circle', hideTabBar: true},
      component: () => import('@/views/app/circle/components/circleQgl')
    },
    {
      // 圈子成员管理
      path: 'circleCygl',
      name: 'circleCygl',
      meta: {moduleName: 'circle', hideTabBar: true},
      component: () => import('@/views/app/circle/components/circleQgl/circleCyglCont/circleCygl')
    },
    {
      // 圈主转让
      path: 'circleZr',
      name: 'circleZr',
      meta: {moduleName: 'circle', hideTabBar: true},
      component: () => import('@/views/app/circle/components/circleQgl/circleZr/circleZr')
    },
    {
      // 圈子管理员添加
      path: 'circleQgly',
      name: 'circleQgly',
      meta: {moduleName: 'circle', hideTabBar: true},
      component: () => import('@/views/app/circle/components/circleQgl/circleQgly/circleQgly')
    },
    {
      // 圈子禁言人员
      path: 'circleManagePerson',
      name: 'circleManagePerson',
      meta: {moduleName: 'circle', hideTabBar: true},
      component: () => import('@/views/app/circle/circleManagePerson')
    },
    {
      // 圈子禁言人员
      path: 'circleJyPerContAdd',
      name: 'circleJyPerContAdd',
      meta: {moduleName: 'circle', hideTabBar: true},
      component: () => import('@/views/app/circle/circleManagePerson/circleJyPerCont/circleJyPerContAdd')
    },
    {
      // 圈子禁言人员
      path: 'circleJyPerContCancal',
      name: 'circleJyPerContCancal',
      meta: {moduleName: 'circle', hideTabBar: true},
      component: () => import('@/views/app/circle/circleManagePerson/circleJyPerCont/circleJyPerContCancal')
    },
    {
      // 删除圈成员
      path: 'deleteCircleMember',
      name: 'deleteCircleMember',
      meta: {moduleName: 'circle', hideTabBar: true},
      component: () => import('@/views/app/circle/deleteMember/index')
    },
    {
      // 添加圈成员
      path: 'addCircleMember',
      name: 'addCircleMember',
      meta: {moduleName: 'circle', hideTabBar: true},
      component: () => import('@/views/app/circle/addMember/index')
    },
    {
      // 添加圈管理员
      path: 'manageSetting',
      name: 'manageSetting',
      meta: {moduleName: 'circle', hideTabBar: true},
      component: () => import('@/views/app/circle/manage/index')
    },
    {
      // 添加圈管理员
      path: 'addCircleManage',
      name: 'addCircleManage',
      meta: {moduleName: 'circle', hideTabBar: true},
      component: () => import('@/views/app/circle/manage/add')
    },
    {
      // 圈子禁言人员
      path: 'circlePersonSearch',
      name: 'circlePersonSearch',
      meta: {moduleName: 'circle', hideTabBar: true},
      component: () => import('@/views/app/circle/circleManagePerson/circlePersonSearch')
    }]
}
