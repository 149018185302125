import Vue from 'vue'
import App from './App.vue'
import Vant from 'vant';
import 'vant/lib/index.css';
import './styles/reset.css'
import './styles/flex.css'
import './styles/common.css'
import './styles/vanUi.css'
import router from './router'
import store from './store'
import "amfe-flexible";
import infiniteScroll from 'vue-infinite-scroll'
import VConsole from 'vconsole'
import Upload from 'alioss-upload'
import {ALI_OSS_TOKEN_URL} from "@/config/config";
import {getOSSSignature} from '@/api/aliyun'
import {getUserById} from "@/api/suser";
import Watermark from '@/utils/watermark.js'

// 输入框自动宽度，苹果上有问题
import vueInputAutoWidth from 'vue-input-autowidth'
Vue.use(vueInputAutoWidth)

// eslint-disable-next-line no-empty
if (process.env.NODE_ENV === 'development1') {
  new VConsole()
}
// new VConsole()
Vue.config.productionTip = false
Vue.use(infiniteScroll)
Vue.use(Vant)
Vue.prototype.$upload = Upload({serveUrl: ALI_OSS_TOKEN_URL, getSignature: getOSSSignature})
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

router.afterEach(() => {
  const {user_name} = store.getters.user;
  if (user_name) {
    getUserById().then(res => {
      // console.log("用户信息",res)
      Watermark.set(`${user_name}${res.empNo}`)
      store.commit('user/SET_USER_DETAIL', res);
    })
  }
})
