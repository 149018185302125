import routerView from "@/components/routerView";

export default {
    name: 'message',
    path: 'message',
    meta: {title: '消息', activeIcon: 'messageActive.png', normalIcon: 'messageNormal.png', moduleName: 'message'},
    redirect: {name: 'messageMainView'},
    component: routerView,
    children: [
        {
            path: 'main',
            name: 'messageMainView',
            meta: {moduleName: 'message'},
            component: () => import('@/views/app/message/main')
        },
        {
            path: 'like',
            name: 'messageLikeView',
            meta: {moduleName: 'message', hideTabBar: true},
            component: () => import('@/views/app/message/like')
        },
        {
            path: 'aboutMe',
            name: 'messageAboutMeView',
            meta: {moduleName: 'message', hideTabBar: true},
            component: () => import('@/views/app/message/aboutMe')
        },
        {
            path: 'comment',
            name: 'messageCommentView',
            meta: {moduleName: 'message', hideTabBar: true},
            component: () => import('@/views/app/message/comment')
        },
        {
            path: 'chat',
            name: 'messageChatView',
            meta: {moduleName: 'message', hideTabBar: true},
            component: () => import('@/views/app/message/chat')
        },
        {
            path: 'information',
            name: 'information',
            meta: {moduleName: 'message', hideTabBar: true},
            component: () => import('@/views/app/message/information')
        },
        {
            path: 'system',
            name: 'systemAdviseView',
            meta: {moduleName: 'message', hideTabBar: true},
            component: () => import('@/views/app/message/system/index')
        },
        {
            path: 'applyJoin',
            name: 'applyJoinView',
            meta: {moduleName: 'message', hideTabBar: true},
            component: () => import('@/views/app/message/applyJoin/index')
        }
    ]
}
