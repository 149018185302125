import routerView from '@/components/routerView'

export default {
    name: 'common',
    path: 'common',
    component: routerView,
    hidden: true,
    meta: { hideTabBar: true },
    children: [
        {
            name: 'search',
            path: 'search',
            meta: { hideTabBar: true, keepAlive: true },
            component: () => import('@/views/app/common/search')
        },
        {
            name: 'searchTow',
            path: 'searchTow',
            meta: { hideTabBar: true, keepAlive: true },
            component: () => import('@/views/app/common/search/indexTow')
        },
        {
            name: 'organization',
            path: 'organization',
            meta: { hideTabBar: true },
            props: route => route.query,
            component: () => import('@/views/app/common/organization')
        },
        {
            name: 'qrcode',
            path: 'qrcode',
            meta: { hideTabBar: true },
            props: route => route.query,
            component: () => import('@/views/app/common/qrcode')
        },
        {
            name: 'forwardToWechat',
            path: 'forwardToWechat',
            meta: { hideTabBar: true },
            props: route => route.query,
            component: () => import('@/views/app/common/forwardToWechat')
        }
    ]
}
