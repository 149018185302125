<template>
  <router-view/>
</template>

<script>
export default {
  name: "index",
  created() {
    // window.addEventListener("focus",()=>{
    //   alert("---a")
    // })

  }
}
</script>

<style scoped>

</style>
