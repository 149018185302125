import axios from 'axios'

/**
 * 请求
 * @param url
 * @param method
 * @param params
 * @param data
 * @returns {Promise<unknown>}
 */
export function request({url, method, params, data} = {}) {
    return new Promise((resolve, reject) => {
        axios({url, method, params, data}).then(({data}) => {
            if (data.success) {
                resolve(data.data)
            } else {
                reject(data)
            }
        })
    })
}

export function noop() {

}
