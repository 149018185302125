import Vue from 'vue'
import Vuex from 'vuex'
import user from './modules/user'
import common from "./modules/common";
import getters from './getters'

Vue.use(Vuex)
const store = new Vuex.Store({
  modules: {
    user, common
  },
  getters,
  state: { returnMessages: 0, refreshData: null }
})

export default store
