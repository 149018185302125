import {noop, request} from "./utils";

/**
 * 阿里 oss 文件 web 直传封装
 * @param serveUrl 用户获取 '签名和Policy' 等信息的应用服务器的URL
 * @param isRandomFileName 是否文件名随机
 * @param successActionStatus 成功后返回的状态码
 * @param getSignature
 * @returns {{multipleFilesUpload: (function(*): (Promise<never>|Promise<minimist.Opts.unknown[]>)), fileUpload: (function(*): Promise<minimist.Opts.unknown>)}}
 */
export default ({ isRandomFileName = true, successActionStatus = 200, getSignature = noop} = {}) => {

    let reqSignature = null


    /**
     * 单文件上传
     * @param file
     */
    async function fileUpload(file) {
        const fileName = file.name
        const now = Date.parse(new Date().toString()) / 1000;
        const suffix = getSuffix(fileName)

        // 签名信息不存在或签名即将过期
        if (!reqSignature || parseInt(reqSignature.expire) < now + 3) {
            // 获取签名
            reqSignature = await getSignature()
        }

        const {host, policy: policyBase64, accessid, signature, callback: callbackbody, dir} = reqSignature


        const reqJson = {
            name: fileName,
            key: dir + (isRandomFileName ? randomString(10) + suffix : "${filename}"),
            policy: policyBase64,
            OSSAccessKeyId: accessid,
            success_action_status: successActionStatus, //让服务端返回200,不然，默认会返回204
            callback: callbackbody,
            signature,
            file
        }
        const formData = new FormData()

        for (let key in reqJson) {
            formData.append(key, reqJson[key])
        }

        return request({url: host, method: 'post', data: formData})

    }

    /**
     * 多文件上传
     * @param files
     * @returns {Promise<never>|Promise<unknown[]>}
     */
    function multipleFilesUpload(files) {
        if (!files.forEach) {
            return Promise.reject('文件类型需为 FileList')
        }

        const tempArr = []

        files.forEach(file => {
            tempArr.push(fileUpload(file))
        })

        return Promise.all(tempArr)
    }


    /**
     * 生成随机字符串
     * @param len
     * @returns {string}
     */
    function randomString(len) {
        len = len || 32;
        let pwd = '';
        const chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
        const maxPos = chars.length;
        for (let i = 0; i < len; i++) {
            pwd += chars.charAt(Math.floor(Math.random() * maxPos));
        }
        return pwd;
    }

    /**
     * 截取文件后缀
     * @param filename
     * @returns {string}
     */
    function getSuffix(filename) {
        const pos = filename.lastIndexOf('.')
        let suffix = ''
        if (pos !== -1) {
            suffix = filename.substring(pos)
        }
        return suffix;
    }


    return {
        multipleFilesUpload,
        fileUpload
    }
}
