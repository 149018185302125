import request from '@/utils/request'

/**
 * todo 创建圈子
 */
export function createCircle(data) {
  if (!data.background) {
    data.background = '#f6f6f6'
  }
  return request({
    url: '/api/zeekr-article-new/circle/create',
    method: 'post',
    data: data
  })
}

/**
 * todo 获取首页推荐的圈子列表
 */
export function getListRecommendCircle() {
  return request({
    url: '/api/zeekr-article-new/circle/listRecommendCircle',
    method: 'get'
  })
}

/**
 * todo 分页获取我加入的圈子列表
 */
export function getListJoinedCircle(params) {
  return request({
    url: '/api/zeekr-article-new/circle/listJoinedCircle',
    method: 'get',
    params
  })
}

/**
 * todo 获取我加入的圈子列表，按帖子未读数量排序
 */
export function getListJoinedCircleMessage() {
  return request({
    url: '/api/zeekr-article-new/circle/listJoinedCircleOrderByUnreadArticle',
    method: 'get'
  })
}

/**
 * todo 获取热门圈子列表
 */
export function getListHotCircle() {
  return request({
    url: '/api/zeekr-article-new/circle/listHotCircle',
    method: 'get'
  })
}

/**
 * todo 分页获取我管理的圈子列表
 */
export function getListManagedCircle(params) {
  return request({
    url: '/api/zeekr-article-new/circle/listManagedCircle',
    method: 'get',
    params
  })
}

/**
 * todo H5-所有启用圈子类型
 */
export function getListCircleType() {
  return request({
    url: '/api/zeekr-article-new/circleType/listCircleType',
    method: 'get'
  })
}

/**
 * todo 分页根据圈子分类获取圈子列表
 */
export function getListCircleByType(params) {
  return request({
    url: '/api/zeekr-article-new/circle/listCircleByType',
    method: 'get',
    params
  })
}

/**
 * todo 获取圈子背景颜色列表
 */
export function getListCircleBackground(params) {
  return request({
    url: '/api/zeekr-article-new/circle/listCircleBackground',
    method: 'get',
    params
  })
}

/**
 * todo 根据圈子标题模糊搜索圈子（公开）
 */
export function getSearchCircleByTitle(params) {
  return request({
    url: '/api/zeekr-article-new/circle/searchCircleByTitle',
    method: 'get',
    params
  })
}

/**
 * todo 加入圈子
 */
export function setApplyJoin(data) {
  if (data.applyReason === '') {
    delete data.applyReason
  }
  return request({
    url: '/api/zeekr-article-new/circleMember/applyJoin',
    method: 'post',
    data
  })
}
/**
 * 获取我感兴趣的圈子
 */
export function getMyInterestCircle(params) {

  return request({
    url: '/api/zeekr-article-new/circle/listJoinedCircleForPublishSquareArticle',
    method: 'get',
    params
  })
}
/**
 * todo 退出圈子（圈普通成员和圈管理员）
 */
export function setQuitCircle(id) {
  return request({
    url: '/api/zeekr-article-new/circleMember/withdraw',
    method: 'get',
    params: {
      circleId: id
    }
  })
}

/**
 * todo 解散圈子（圈主）
 */
export function setDismissCircle(id) {
  return request({
    url: '/api/zeekr-article-new/circleManage/dismiss',
    method: 'post',
    params: {
      circleId: id
    }
  })
}

/**
 * todo 查看圈子详情
 */
export function getCircleDetail(id) {
  return request({
    url: '/api/zeekr-article-new/circle/detail',
    method: 'get',
    params: {
      circleId: id
    }
  })
}

/**
 * todo 修改圈子名称（圈主）
 */
export function updateCircleTitle(data) {
  return request({
    url: '/api/zeekr-article-new/circleManage/title',
    method: 'post',
    data
  })
}

/**
 * todo 修改圈子简介（圈主）
 */
export function updateCircleIntroduction(data) {
  return request({
    url: '/api/zeekr-article-new/circleManage/introduction',
    method: 'post',
    data
  })
}

/**
 * todo 修改圈子头像（圈主）
 */
export function updateCircleAvatar(data) {
  return request({
    url: '/api/zeekr-article-new/circleManage/avatar',
    method: 'post',
    data
  })
}

/**
 * todo 修改圈子类型（圈主）
 */
export function updateCircleType(data) {
  return request({
    url: '/api/zeekr-article-new/circleManage/type',
    method: 'post',
    data
  })
}

/**
 * todo 修改圈子背景（圈主）
 */
export function updateCircleBackground(data) {
  return request({
    url: '/api/zeekr-article-new/circleManage/background',
    method: 'post',
    data
  })
}

/**
 * todo 修改私密圈开关（圈主）
 */
export function updatePrivateSwitch(data) {
  return request({
    url: '/api/zeekr-article-new/circleManage/privateSwitch',
    method: 'post',
    data
  })
}

/**
 * todo 修改成员加入申请开关（圈主）
 */
export function updateJoinApplySwitch(data) {
  return request({
    url: '/api/zeekr-article-new/circleManage/joinApplySwitch',
    method: 'post',
    data
  })
}

/**
 * todo 分页获取广场发帖时我加入的圈子列表（公开未被禁言的）
 */
export function getMyJoinListNotForbidden(params) {
  return request({
    url: '/api/zeekr-article-new/circle/listJoinedCircleForSquarePublishArticle',
    method: 'get',
    params
  })
}

/**
 * todo 分页获取广场发帖时我加入的被禁言的圈子列表
 */
export function getMyJoinListHaveForbidden(params) {
  return request({
    url: '/api/zeekr-article-new/circle/listJoinedMutedCircleForSquarePublishArticle',
    method: 'get',
    params
  })
}

/**
 * todo 分页获取圈成员列表
 */
export function getListMember(data) {
  return request({
    url: '/api/zeekr-article-new/circleMember/listMember',
    method: 'post',
    data
  })
}


/**
 * 根据圈子标题模糊搜索圈子（公开）
 */
export function searchCircleByTitle(params) {
  params.title = params.keyword
  return request({
    url: '/api/zeekr-article-new/circle/searchCircleByTitle',
    method: 'get',
    params
  })
}

/**
 * 查询圈子帖子列表
 */
export function listCircleArticle(params) {
  return request({
    url: '/api/zeekr-article-new/circleArticle/listCircleArticle',
    method: 'get',
    params
  })
}
/**
 *
根据关键词搜索圈子的帖子列表
 *
 */

export function getKeywordSearchList (params) {
  return request({
    url: '/api/zeekr-article-new/circleArticle/listCircleArticleByKeyword',
    method: 'get',
    params
  })
}

/**
 * 分页获取圈成员列表
 */
export function listCircleMember(data) {
  return request({
    url: '/api/zeekr-article-new/circleMember/listMember',
    method: 'post',
    data
  })
}

/**
 * 分页获取可以删除的圈成员列表（圈主和圈管理员展示）
 */
export function getListUserForRemove(data) {
  return request({
    url: '/api/zeekr-article-new/circleMember/listUserForRemove',
    method: 'post',
    data
  })
}

/**
 * 分页获取可以禁言的成员列表（圈主和管理员可以操作）
 */
export function getListCanMuteMember(data) {
  return request({
    url: '/api/zeekr-article-new/circleMember/listCanMuteMember',
    method: 'post',
    data
  })
}

/**
 * 分页获取被禁言的成员列表（圈主和管理员可以操作）
 */
export function listMutedMember(data) {
  return request({
    url: '/api/zeekr-article-new/circleMember/listMutedMember',
    method: 'post',
    data
  })
}

/**
 * 获取用户在圈子中的身份
 */
export function getMemberIdentity(params) {
  return request({
    url: '/api/zeekr-article-new/circleMember/getMemberIdentity',
    method: 'get',
    params
  })
}

/**
 * 转让圈主
 */
export function updateTransferLeader(params) {
  return request({
    url: '/api/zeekr-article-new/circleMemberManage/transferLeader',
    method: 'post',
    params
  })
}

/**
 * 移除圈管理员
 */
export function removeManager(params) {
  return request({
    url: '/api/zeekr-article-new/circleMemberManage/removeManager',
    method: 'post',
    params
  })
}

/**
 * 批量添加圈成员禁言
 * /zeekr-article-new/circleMemberManage/batchRemoveMemberMuted
 */
export function batchAddMemberMuted(data) {
  return request({
    url: '/api/zeekr-article-new/circleMemberManage/batchAddMemberMuted',
    method: 'post',
    data
  })
}


/**
 * 批量解除圈成员禁言
 * /zeekr-article-new/circleMemberManage/batchRemoveMemberMuted
 */
export function batchRemoveMemberMuted(data) {
  return request({
    url: '/api/zeekr-article-new/circleMemberManage/batchRemoveMemberMuted',
    method: 'post',
    data
  })
}

/**
 * 批量移除圈成员
 * /zeekr-article-new/circleMemberManage/batchRemoveMember
 */
export function batchRemoveMember(data) {
  return request({
    url: '/api/zeekr-article-new/circleMemberManage/batchRemoveMember',
    method: 'post',
    data
  })
}

/**
 * 批量添加圈成员
 */
export function batchAddMember(data) {
  return request({
    url: '/api/zeekr-article-new/circleMemberManage/batchAddMember',
    method: 'post',
    data
  })
}

/**
 * 获取圈管理员列表
 */
export function getListManageMember(circleId) {
  return request({
    url: '/api/zeekr-article-new/circleMember/listManageMember',
    method: 'get',
    params:{
      circleId
    }
  })
}

/**
 * 批量添加圈管理员
 */
export function batchAddManager(data) {
  return request({
    url: '/api/zeekr-article-new/circleMemberManage/batchAddManager',
    method: 'post',
    data
  })
}

